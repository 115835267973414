
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;700&display=swap');
@viewport {
    orientation: portrait;
}
$layout-max-width:900px;
$column-spacing:46px;
$page-display: unset;
$padding-right: calc(env(safe-area-inset-right) + 96px);
$padding-left: calc(env(safe-area-inset-left) + 96px);
$padding-right-mobile: calc(env(safe-area-inset-right) + 24px);
$padding-left-mobile: calc(env(safe-area-inset-left) + 24px);
$header-cover-height: 30vh;
$header-title-align: left;
$header-icon-align: -112px auto auto auto;
$header-display: block;
$heading-size: 1rem;
$heading1-size: calc(1rem* 1.875);
$heading2-size: calc(1rem* 1.5);
$heading3-size: calc(1rem* 1.25);
$heading4-size: calc(var($heading-size) * 1);
$heading5-size: calc(var($heading-size) * 0.8125);
//
//html ::-webkit-scrollbar {
//    width: 15px;
//    background: #0c0c0c;
//}
//
//html ::-webkit-scrollbar-thumb {
//    background-color: #a4a4a4;
//    border: 3px solid transparent;
//    border-radius: 50px;
//    background-clip: padding-box;
//}


html,
body,
#root {
    height: 100%;
    width: 100%;
    //overflow: hidden;
    background-color: black;
    padding: 0;
    margin: 0;
    color-scheme: dark;

}

@font-face {
    font-family: "DigitalDisco";
    src: local("DigitalDiso"),
    url("./fonts/DigitalDisco/DigitalDisco.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "RobotoRegular";
    src: local("RobotoMono"),
    url("./fonts/RobotoMono/RobotoMono-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "RobotoLight";
    src: local("RobotoMono"),
    url("./fonts/RobotoMono/RobotoMono-Light.ttf") format("truetype");
}

@font-face {
    font-family: "RobotoMedium";
    src: local("RobotoMono"),
    url("./fonts/RobotoMono/RobotoMono-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "RobotoBold";
    src: local("RobotoMono"),
    url("./fonts/RobotoMono/RobotoMono-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"),
    url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

h1,
p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

option[default] {
    display: none;
}

.page-wrap {
    min-height: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
.page-wrap main {
    flex-grow: 1;

    box-sizing: border-box;

}


.mint-btn {
    background-color: #6ef9d9;
    padding: 0.5rem 1.5rem;
    font-size: 2rem ;
    margin-top: 17vh;
    color: #171b4d;
    font-family: "RobotoBold", sans-serif;
    border-radius: 10px;
    &:hover{
        cursor: pointer;
        background-color: #33c4a6;

    }

}

.refresh-btn {
    background-color: transparent;
    display: contents;
    text-decoration: underline;

}

.nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 3rem;
    box-sizing: border-box;
    border-radius: 10px;
    color: white;
    background-color: rgb(0 0 0 / 20%);
}

.nav-active {
    display: none;
}

footer {
    transition-delay: 1s;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    left:0;
    bottom:0;
    right:0;
    justify-content: center;
    border-radius: 10px;
    padding: 8px 1rem;
    color: white;
    background-color: rgb(0 0 0 / 20%);

    //animation: fadeIn 2s;
    //-webkit-animation: fadeIn 2s;
    //-moz-animation: fadeIn 2s;
    //-o-animation: fadeIn 2s;
    //-ms-animation: fadeIn 2s;
}

#loading-banner {
    border-radius: 20px;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 16%;
    margin-right: 30%;
    padding: 15px;
    height: 40vh;
    border: 1px solid gray;
    width: 40vw;
    background-color: #010103;
    z-index: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.content-box {
    border-radius: 6px;
    background-color: rgb(0 0 0 / 50%);
    height: 100%;
}

.mason-box {
    border-radius: 6px;
    width: auto;
    background-color: rgb(0 0 0 / 50%);
}

.super-content-wrapper {
    flex-grow: 1;
    box-sizing: border-box;

}

.super-content {
    padding-left: $padding-left;
    padding-right: $padding-right;
    padding-bottom: 30vh;
}

.max-width {
    max-width: $layout-max-width;
    margin: auto;
}

.litepaper-header-image {
width: auto;
    object-fit: contain;
    height: 30vh;
    margin: auto;
}

.header-image-container {
    display: flex;
    padding-top: 3rem;
    filter: drop-shadow(0 0 1rem #558cbe);
}

.heading {
    font-size: 2rem;
    font-family: 'RobotoMedium', sans-serif !important;
    //letter-spacing: 2px;
    font-style: italic;
    color: white;
}

.sub-heading {
    font-size: 2rem;
    font-family: 'RobotoMedium', sans-serif !important;
    font-weight: 600;
    color: #4790d4;
}

.box-content {
    font-size: 0.98rem;
    font-family: 'RobotoRegular', sans-serif !important;
    //font-style: italic;
    line-height: 1.4;
    color: white;
}

.box-heading {
    font-size: 1.5rem;
    font-family: 'RobotoBold', sans-serif !important;
    //font-style: italic;
    color: white;
}

.notion-header-content {
    max-width: 900px;
    margin: auto;
    color: white;
}

.notion-header-wrapper {
    margin-top: 96px;
    margin-bottom: 4px;
    position: relative;
    z-index: 1;
}

.notion-header {
    font-weight: 700;
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 2.5rem;
    line-height: 2.5rem;
    min-height: 48px;
    white-space: pre-wrap;
    word-break: break-word;
    text-align: left;
    display: block;
    padding: 2px;
    font-family: "RobotoRegular";

}

.notion-root {
    display: flex;
    flex-direction: column;
}

h1.notion-heading {
    font-size: calc(1rem * 1.8);
}

h2.notion-heading {
    font-size: $heading2-size;
    font-weight: 600;
}

.notion-heading .notion-semantic-string {
    line-height: 1.3em;
    color: white;
}

.notion-semantic-string {
    line-height: 1.3rem;
    color: white;
    white-space: pre-wrap;
    font-family: "RobotoRegular", serif;
    word-break: break-word;
}

.notion-heading {
    font-weight: 600;
    margin-bottom: 0px;
    position: relative;
    font-family: "RobotoRegular";
    padding: 3px 2px;
}

.notion-text {
    min-height: calc(1.5rem + 10px);
}

.notion-text__content {
    padding: 3px 2px;
    margin: 0px;
}
.notion-embed {
    width: 100%;
    align-self: center;
}
.notion-embed__content {
    display: flex;
    position: relative;
    height: 100%;
}

.notion-yt {

        position: absolute;
        inset-inline-start: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: none;
        pointer-events: auto;
        background-color: #f7f6f5;

}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.slick-list {
    height: 100vh !important;
}

.slick-track {
    height: 100% !important;
}

.slick-slider {
    flex: 1;
}


.slick-arrow {
    display: none !important;
}


.slick-dots {
    right: -2rem !important;
    position: absolute !important;
    background-color: #0a0a0a !important;
    border-radius: 10px !important;
    padding: 10px !important;
    top: 48% !important;
    width: auto !important;
    height: fit-content !important;
    transform: rotate(90deg) !important;
    box-shadow: 0 0 10px 5px #0b425a;
}

.slick-dots li.slick-active button:before {
    opacity: .75 !important;
    color: white !important;
}

.slick-dots li button:before {
    font-size: 0.55rem !important;
    color: white !important;
}


.slick-slide {
    pointer-events: none !important;
}

.slick-current {
    pointer-events: unset !important;
}

/*.slick-slide .slick-active { z-index: 999 !important; }*/

.back-btn {
    text-align: center;
    all: unset;
    cursor: pointer;
    font-size: 0.8rem;
    padding: 0 1ch;
    color: white;
    min-height: 38px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 5px;
    background: #06212f;
    box-shadow: -1px 0px 11px 3px #024a71;
    font-family: "RobotoLight", serif;
}


.wrapper {
    background: url('https://cdn.discordapp.com/attachments/1019476180233101332/1019515140045029437/BG6re_0001_Layer-34-copy-4.png') no-repeat;
    background-color: rgba(18,18,18, 0.9);
    background-blend-mode: darken;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
}

.headerBtnImg {
    width: auto;
    object-fit: cover;
    height: 1.2rem;
}

.headerLogo {
    width: auto;
    object-fit: contain;
    height: 2.5em;
}

.parent {
    align-items: center;
    display: flex;
    margin: 5vh 0;
    box-sizing: border-box;
    justify-content: center;
    width: 100%;
    color: white;
    background-color: transparent;
    /*opacity: 0;*/
    /*transition: opacity 500ms ease 0s, visibility 500ms ease 0s;*/
}

.timeline-parent {
    height: 100vh;
    align-items: center;
    display: flex;
    margin: 0;
    box-sizing: border-box;
    justify-content: center;
    width: 100%;
    background-color: transparent;
}

.desc {
    color: #fff;
    font-size: 18px;
    margin-top: 18px;
    text-align: center;
    font-family: "Montserrat";
    line-height: 28px;
    display: block;
   // animation: reveal 1s cubic-bezier(0.2, 0, 0.175, 1);
}

@keyframes reveal {
    0% {
        transform: translate(0, 100%);
    }
    100% {
        transform: translate(0, 0);
    }
}


.descTime {
    color: #fff;
    font-size: 1.12rem;
    margin-top: 18px;
    text-align: left;
    letter-spacing: -0.03em;
    font-family: "Montserrat";
    display: block;
    //animation: reveal 1.5s cubic-bezier(0.2, 0, 0.175, 1);
    line-height: 28px;
}

.storyBox {
    display: flex;
    text-align: center;
    align-items: center;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    max-width: 40vw;
}

.title {
    color: #fff;
    font-size: 3rem;
    margin: 0;
    display: block;
    //animation: reveal 1.5s cubic-bezier(0.2, 0, 0.175, 1);
    font-family: "RobotoRegular";
    line-height: 70px;
    letter-spacing: -0.02em;
}

.storyImage {
    width: 100%;
    height: auto;
    object-fit: cover;
    opacity: 1;
    transform-origin: left;
}

.reveal {
    visibility: hidden;
    position: relative;
    width: 100%;
    height: auto;
    /*max-width: 700px;*/
    overflow: hidden;
}

/*SELECT STYLES*/

/*SELECT STYLES END*/


.img-anim {
    transition-timing-function: ease-in;
    transition: opacity 1s;
    transition-delay: 1s;
}

.heroImage {
    width: auto;
    height: 30vh;
    object-fit: contain;
    margin-top: 6vh;
}

.timelineBox1 {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 38vw;
}

.timelineBox2 {
    padding-top: 1rem;
    display: flex;
    margin-left: 2rem;
    justify-content: center;
    flex-direction: column;
    max-width: 38vw;
}

.videoPlayer {
    box-shadow: 0 0 10px;
    min-height: 32vh;
    min-width: 30vw;
}

.footer-parent {
    height: 100%;
    align-items: center;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    width: 100%;
    background-color: transparent;
    /*padding-top: 5vh;*/
    flex-direction: column;
}

@media only screen and (max-width: 1280px) {
    .title {
        font-size: 1.6rem;
        line-height: 20px;
        letter-spacing: -0.02em;
    }
    .back-btn {
        font-size: 0.8rem;
        padding: 2px 4px;
        text-align: center;
    }
    .HTSelect__control {
        font-size: 0.9rem;

    }
    .desc {
        font-size: 1rem;
        line-height: 25px;
    }

    .footer-parent .storyBox {
        padding-top: 0;
        margin-bottom: 23vh;
    }
    .headerLogo {
        width: auto;
        object-fit: contain;
        height: 3em;
    }
}
.bm-menu {
    padding: 10vh 10px;
    background-color: #0a0a0a;
}

.bm-menu-wrap {
    overflow-y: hidden;
}

.header-content-xs {
    visibility: hidden;
}

.bm-item {
    margin-top: 10px;
    box-shadow: none;
    background: none;
    border-bottom: 1px solid #024a71;
}

.bm-burger-button {
    display: none;
}

.bm-item .HTSelect__control {
    border: none;
    background-color: #001924;
    box-shadow: none;
    -webkit-border-before: none;

}

@media (max-width: 600px) {
    .heroImage {
        width: 80vw;
    }

    .headerLogo {
        display: none;
    }
    .super-content {
        padding-left: $padding-left-mobile;
        padding-right: $padding-right-mobile;
        padding-bottom: 180px;
    }

    .litepaper-header-image {
        width: auto;
        object-fit: contain;
        height: 20vh;
        margin: auto;
    }

    .storyBox {
        display: flex;
        text-align: center;
        align-items: center;
        align-self: center;
        justify-content: center;
        flex-direction: column;
        max-width: 75vw;
    }

    .back-btn {
        font-size: 0.8rem;
        padding: 0 4px;
        text-align: center;
    }
    .HTSelect__value-container {
        width: max-content;
    }

    .HTSelect__control {
        font-size: 0.8rem !important;
    }
    .HTSelect__singleValue {
        font-size: 0.8rem;

    }

    .select-dropdown select {
        font-size: 0.5em;
    }


    .videoPlayer {
        box-shadow: 0 0 10px;
        min-height: 38vw;
        min-width: 76vw;
    }

    .timeline-parent {
        height: 100vh;
        align-items: center;
        display: flex;
        margin: 0;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: center;
        width: 100%;
        background-color: transparent;
    }

    .title {
        color: #fff;
        font-size: 1.1rem;
        margin: 0;
        font-family: "RobotoRegular";
        line-height: 25px;
        letter-spacing: -0.02em;
    }

    .desc {
        color: #fff;
        font-size: 13px;
        margin-top: 5px;
        text-align: center;
        font-family: "Montserrat";
        line-height: 18px;
    }

    .descTime {
        color: #fff;
        font-size: 0.8rem;
        margin-top: 18px;
        text-align: justify;
        letter-spacing: -0.08em;
        font-family: "Montserrat";
        line-height: 18px;
    }

    .timelineBox1 {
        max-width: 75vw;
    }

    .timelineBox2 {

        margin-left: 0;
        justify-content: center;
        flex-direction: column;
        max-width: 75vw;
    }

}

@media (max-width: 500px) {
    .sub-heading {
        font-size: 1.2rem;
    }

    .box-heading {
        font-size: 1rem;
    }

    .box-content {
        font-size: 0.8rem;
    }

    .text-container {
        padding: 0 12px;
        width: 100%;
    }
    .slick-dots {
        display: none !important;
    }

    .timeline-parent {
        justify-content: flex-start;
    }


    .header-content-md {
        display: none !important;
    }
    .header-content-xs {
        visibility: visible;
        width: 100%;
    }

    @media only screen and (min-width: 992px)  {
        .nav {
            padding-left: 2rem;
            padding-right: 2rem;
        }

    }

    .nav {

            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            padding: .5rem 1rem;

        //&::-webkit-scrollbar {
        //    height: 10px;
        //    background: #2e3131;
        //    -webkit-appearance: none;
        //    //border-left: 1px solid #e8e8e8;
        //    //border-right: 1px solid  #e8e8e8;
        //}
        //
        //&::-webkit-scrollbar-thumb {
        //    background-color: #5d5d5d;
        //    border: 3px solid transparent;
        //    border-radius: 50px;
        //    background-clip: padding-box;
        //    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
        //    box-shadow: 0 0 1px rgba(255,255,255,.5);
        //}

    }
}

.mint-restrict-window {
    display: none !important;
}

@media screen and (orientation: portrait) and (hover: none) and (pointer: coarse) {
    .mint-restrict-window {
        display: block !important;
    }
}

@media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {

    /* your CSS to target only landscape mobile users */
    .heroImage {
        margin-bottom: 8vh;
    }
    .select-dropdown select {
        font-size: 0.5em;
    }

    .title {
        color: #fff;
        font-size: 1.1rem;
        margin: 0;
        font-family: "RobotoRegular";
        line-height: 25px;
        letter-spacing: -0.02em;
    }

    footer .title {
        font-size: 0.9rem !important;
        padding: 5px !important;
        line-height: 10px !important;
    }

    footer .headerBtnImg {
        width: auto;
        object-fit: cover;
        height: 1rem;


    }

    .footer-parent {
        height: 100%;
        align-items: center;
        position: relative;
        display: flex;
        box-sizing: border-box;
        justify-content: flex-start;
        width: 100%;
        background-color: transparent;
        /*padding-top: 5vh;*/
        flex-direction: column;
    }

    .desc {
        color: #fff;
        font-size: 13px;
        margin-top: 5px;
        text-align: center;
        font-family: "Montserrat";
        line-height: 18px;
    }

    .storyBox {
        padding-top: 0;
        display: flex !important;
        /* text-align: center; */
        margin-bottom: 5%;
        align-items: flex-start !important;
        align-self: center !important;
        justify-content: space-between !important;
        flex-direction: row;
        max-width: 78vw !important;
    }

    .mobile-landscape {
        text-align: left;
        width: 75vw;
    }

    .reveal {
        visibility: hidden;
        position: relative;
        width: 95%;
        padding-left: 10px;
        height: auto;
        /*max-width: 700px;*/
        overflow: hidden;
    }

    .mobile-landscape .desc {
        text-align: left;
    }

    .descTime {
        color: #fff;
        font-size: 0.8rem;
        margin-top: 10px;
        text-align: justify;
        margin-right: 5px;
        letter-spacing: -0.03em;
        font-family: "Montserrat";
        line-height: 16px;
    }

    .timelineBox1 {
        padding-top: 1rem;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        height: 90%;
        max-width: 44vw;
        overflow-y: scroll;
    }

    .timelineBox2 {
        padding-top: 0px;
    }

    .slick-dots li button:before {
        font-size: 0.42rem !important;
        color: white !important;
    }

    .slick-dots {
        right: -3.4rem !important;
        border-radius: 8px !important;
        padding: 2px !important;
        top: 38% !important;
    }
}

